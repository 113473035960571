@font-face {
  font-family: "KOTRA_BOLD-Bold";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10-21@1.1/KOTRA_BOLD-Bold.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: white;
  font-family: "KOTRA_BOLD-Bold";
  color: black;
  margin: auto;
}

img {
  width: 150px;
  margin: 3px;
}

.char-search {
  width: 70%;
  height: 40px;
  margin: 10px;
  font-size: 20px;
}

.main-layout {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  margin: auto;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.char-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
}

.char-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
}

.char-name {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}
#char-kr-name {
  font-size: 20px;
}

.char-pos {
  margin-left: 5px;
  font-size: 18px;
}
#goose {
  color: white;
}
#duck {
  color: #ec0000;
}

.char-desc {
  font-size: 17px;
}
.char-box {
  max-width: 100%;
  width: 200px;
  background-color: darkgray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  padding: 10px;
  font-size: 3px;
}

.goose {
  color: white;
  border: 4px solid white;
}
.duck {
  color: #ec0000;
  border: 4px solid #ec0000;
}
.neutral {
  color: #488dff;
  border: 4px solid #488dff;
}
.grd {
  color: #f27a8e;
  border: 4px solid #f27a8e;
}

#DodoBird {
  border: 4px solid #ffe302;
}
#Vulture {
  border: 4px solid #488dff;
}
#Pigeon {
  border: 4px solid #e76300;
}
#Falcon {
  border: 4px solid #13d0ca;
}
#DuelingDodos {
  border: 4px solid #ffe302;
}
#Pelican {
  border: 4px solid #07db17;
}
#lover {
  border: 4px solid #f27a8e;
}
